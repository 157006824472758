import React from 'react';
import styled from 'styled-components';
import map from 'lodash/map';

import { pricingTypes } from '../types/propTypes';
import { Container, Inner } from './Elements';
import { font, colors, boxShadow } from '../consts/style';
import mq from '../style/mq';

const Wrapper = styled.div`
  margin-bottom: 8rem;
  ${Inner} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const Heading = styled.h1`
  ${font.heading};
  text-align: center;
  color: white;
  margin-bottom: 8rem;
  width: 100%;
`;

const Block = styled.div`
  background: ${props => props.color};
  width: 49%;
  margin-bottom: 2rem;
  padding: 4.4rem;
  text-align: center;
  color: ${colors.dark};
  ${boxShadow.default};
  h3 {
    ${font.h3};
    margin: 0;
  }
  h2 {
    ${font.h2};
    text-transform: unset;
    margin: 4rem 0 5.2rem;
  }
  p {
    ${font.pLarge};
  }
  ${mq.tabletSmall`
    width: 100%;
  `}
`;
const colorArr = [colors.teal, colors.yellow, colors.orange, colors.pink];

export default function Pricing({ pricing, title }) {
  return (
    <Wrapper>
      <Container>
        <Inner>
          <Heading>{title}</Heading>
          {map(pricing, (p, i) => {
            return (
              <Block key={p.id} color={colorArr[i]}>
                <h3>{p.quantity}</h3>
                <h2>{p.cost}</h2>
                <div dangerouslySetInnerHTML={{ __html: p.body }} />
              </Block>
            );
          })}
        </Inner>
      </Container>
    </Wrapper>
  );
}

Pricing.propTypes = pricingTypes;
