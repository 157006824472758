import React, { Fragment } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import { blogCardProps } from '../types/propTypes';
import { font, colors } from '../consts/style';
import mq from '../style/mq';
import Chevron from './icons/Chevron';

const Outer = styled.div`
  margin-bottom: 2.4rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div`
  min-height: 64.4rem;
  border-top: 1rem solid ${props => props.highlightColor};
  border-left: 1rem solid ${props => props.highlightColor};
  padding: 2.4rem;
  padding-right: 3.4rem;
  padding-bottom: 3.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  ${mq.tabletSmall`
    min-height: 50rem;
  `}

  .button {
    height: 2rem;
    a {
      ${font.button};
      padding: 0;
      color: ${props => props.highlightColor};
      text-decoration: none;
      display: flex;
      align-items: center;
      svg {
        width: 1.2rem;
        margin-left: 1.6rem;
      }
    }
  }

  h2 {
    ${font.h3};
    margin-top: 2.4rem;
    color: white;
    text-align: left;
  }

  p {
    ${font.p};
    display: -webkit-box;
    color: white;
    text-align: left;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  height: 18.3rem;
  position: relative;
`;

const PostImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 18.3rem;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`;

const FeaturedIn = styled.div`
  background: ${props => props.highlightColor};
  color: ${colors.blue};
  margin-top: 1.6rem;
  padding: 0 0.8rem;
  text-align: left;
  span {
    ${font.featuredIn};
  }
`;

const Gradient1 = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 1rem;
  width: 100%;
  background: linear-gradient(
    90deg,
    ${props => props.highlightColor} 0%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0)
  );
`;
const Gradient2 = styled(Gradient1)`
  height: 100%;
  width: 1rem;
  background: linear-gradient(
    180deg,
    ${props => props.highlightColor} 0%,
    rgba(0, 0, 0, 0) 80%,
    rgba(0, 0, 0, 0)
  );
`;

export default function BlogCard({ post, highlightColor }) {
  const excerpt = post.shortDescription.substr(0, 88) + '\u2026';
  return (
    <Fragment>
      <Outer className="col-md-4 col-sm-6 col-xs-12">
        <Wrapper highlightColor={highlightColor}>
          <div className="content">
            <ImgWrapper>
              <PostImg
                fluid={post.featuredImage.fluid}
                backgroundColor={highlightColor}
              />
            </ImgWrapper>
            {post.featuredIn && (
              <FeaturedIn highlightColor={highlightColor}>
                <span>Featured in {post.featuredIn}</span>
              </FeaturedIn>
            )}
            <h2>{post.title}</h2>
            <p>{excerpt}</p>
          </div>
          <div className="button">
            <Link to={`/blog/${post.slug}/`}>
              <span>Read More</span>
              <Chevron color={highlightColor} />
            </Link>
          </div>
          <Gradient1 highlightColor={highlightColor} />
          <Gradient2 highlightColor={highlightColor} />
        </Wrapper>
      </Outer>
    </Fragment>
  );
}

BlogCard.propTypes = blogCardProps;
