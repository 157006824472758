import React, { Fragment } from 'react';
import styled from 'styled-components';

import { section1Types } from '../types/propTypes';
import { colors, font } from '../consts/style';
import { Container, Inner } from './Elements';
import mq from '../style/mq';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  background: ${colors.blue};
  padding: 8rem 0;
  ${mq.tablet`
  padding: 4rem 0;
  `}
  ${Inner} {
    display: flex;
    align-items: center;
    ${mq.tabletSmall`
    flex-wrap: wrap;
    justify-content: center;
    `}
  }
`;

const Image = styled.img`
  width: 38rem;
  max-width: 100%;
`;

const Content = styled.div`
  color: white;
  ${font.pLarge};
  padding-left: 1.2rem;

  .buttons {
    margin-top: 2.4rem;
  }

  a {
    ${font.button};
    background: ${colors.yellow};
    color: ${colors.blue};
    border-radius: 12rem;
    padding: 0.4rem 2.4rem 0.2rem;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
      background: ${colors.teal};
    }
    &:hover {
      text-decoration: none;
      color: ${colors.blue};
    }
  }
  ${mq.tabletSmall`
    margin: 0 auto;
    width: 100%;
  `}
`;

export default function Section1({ gif, text }) {
  return (
    <Fragment>
      <Wrapper>
        <Container>
          <Inner>
            <Image src={gif} />
            <Content>
              <span>{text}</span>
              <div className="buttons">
                <a
                  id="Setmore_button_iframe"
                  href="https://my.setmore.com/bookingpage/8a305d39-5ae5-4dfd-9dda-0df93ae34d54"
                >
                  Book a Demo
                </a>
                <Link to="/sign-up/">Get Lantern</Link>
              </div>
            </Content>
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}

Section1.propTypes = section1Types;
