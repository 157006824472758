import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { colors, font } from '../consts/style';
import mq from '../style/mq';
import { Container, Inner } from './Elements';

import appStore from '../images/app-store.png';
import googlePlay from '../images/google-play.png';

const PhoneImg = styled(Img)`
  max-width: 30rem;
  width: 100%;
`;

const Wrapper = styled.div`
  background: ${colors.yellow};

  ${Inner} {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .image-block,
  .content-block {
    width: 50%;
    order: 0;

    ${mq.tabletSmall`
        width: 100%;
    `}
  }

  ${mq.tabletSmall`
       .image-block {
         order: 1;
         padding-top: 4rem;
       }
       .content-block {
         order: 0;
         padding-top: 4rem;
       }
    `}

  .image-block {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 6rem;
  }

  .content-block {
    h3 {
      ${font.h2};
      color: ${colors.blue};
      max-width: 100%;
    }
    ${font.p};

    ul {
      list-style: none;
      padding-left: 2.4rem;
      li {
        position: relative;
        text-transform: uppercase;
        &:before {
          content: '';
          width: 0;
          height: 0;
          border-top: 0.8rem solid transparent;
          border-bottom: 0.8rem solid transparent;
          border-left: 1.2rem solid ${colors.blue};
          position: absolute;
          top: 0.2rem;
          left: -2.4rem;
          border-radius: 50%;
          ${mq.tabletSmall`
                border-top: 0.6rem solid transparent;
                border-bottom: 0.6rem solid transparent;
                border-left: 1rem solid  ${colors.blue};
                top: 0.2rem;
            `}
        }
      }
    }
    .icons {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      a {
        margin-right: 1.2rem;
      }
      img {
        border-radius: 1rem;
        width: 12rem;
      }
    }
  }
`;

export default function AppBlock({
  appSectionTitle,
  appSectionBody,
  appSectionImage,
  appStoreUrl,
  googlePlayStoreUrl,
}) {
  return (
    <Fragment>
      <Wrapper>
        <Container>
          <Inner>
            <div className="image-block">
              <PhoneImg fluid={appSectionImage.fluid} />
            </div>
            <div className="content-block">
              <h3>{appSectionTitle}</h3>
              <div dangerouslySetInnerHTML={{ __html: appSectionBody }}></div>
              <div className="icons">
                <a target="blank" href={appStoreUrl}>
                  <img src={appStore} alt="App Store" />
                </a>
                <a target="blank" href={googlePlayStoreUrl}>
                  <img src={googlePlay} alt="Google Play Store" />
                </a>
              </div>
            </div>
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}

AppBlock.propTypes = {
  appSectionTitle: PropTypes.string.isRequired,
  appSectionBody: PropTypes.string.isRequired,
  appSectionImage: PropTypes.object.isRequired,
  appStoreUrl: PropTypes.string.isRequired,
  googlePlayStoreUrl: PropTypes.string.isRequired,
};
