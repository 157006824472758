/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { videoTypes } from '../types/propTypes';

const Wrapper = styled.div`
  iframe {
    border: none;
  }
`;

export default function Video({ id }) {
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');
      Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p));
    }
  }, []);

  return (
    <Wrapper>
      <div className="js-player">
        <iframe
          src={`https://player.vimeo.com/video/${id}?loop=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`}
          allowfull="true"
          allowtransparency="true"
          allow="autoplay"
        ></iframe>
      </div>
    </Wrapper>
  );
}

Video.propTypes = videoTypes;
