import React, { Fragment } from 'react';
import styled from 'styled-components';

import { iconTypes } from '../../types/propTypes';

const Icon = styled.svg`
  transition: all 0.4s ease;
  max-width: 100%;
`;

export default function Chevron({ color }) {
  return (
    <Fragment>
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.61 19.32">
        <defs></defs>
        <title>chevron</title>
        <g data-name="Layer 2">
          <g data-name="Layer 1">
            <g data-name="Path 1925">
              <polygon
                fill={color}
                points="4.95 19.32 0 14.37 4.71 9.66 0 4.95 4.95 0 14.61 9.66 4.95 19.32"
              />
            </g>
          </g>
        </g>
      </Icon>
    </Fragment>
  );
}

Chevron.propTypes = iconTypes;
