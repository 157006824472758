import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import map from 'lodash/map';
import Img from 'gatsby-image';

import { featureTypes } from '../types/propTypes';
import { Container, Inner } from './Elements';
import { font, colors, boxShadow } from '../consts/style';
import { float } from '../style/animations';
import mq from '../style/mq';

const Wrapper = styled.div`
  margin-bottom: 8rem;
`;

const Heading = styled.h1`
  ${font.heading};
  text-align: center;
  color: white;
  margin-bottom: 8rem;
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  .image {
    width: 50%;
  }
  .content {
    width: 42%;
    color: white;
    h3 {
      ${font.h3};
    }
    p {
      ${font.p};
      margin: 2rem 0;
    }
    button {
      background: ${colors.teal};
      color: ${colors.dark};
      ${font.button};
      ${boxShadow.default};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
  &:nth-child(odd) {
    .image {
      order: 1;
    }
    .content {
      order: 0;
    }
  }
  ${mq.tabletSmall`
    flex-wrap: wrap;
    margin-bottom: 8rem;
    .content,
    .image {
      width: 100%;
    }
    &:nth-child(odd) {
      .image {
        order: 0;
      }
      .content {
        order: 1;
      }
    }
  `}
`;

const Icon = styled(Img)`
  position: relative;
  animation: ${float} 4s ease-in-out infinite;
  ${mq.tabletSmall`
    animation: none;
    width: 44rem;
    max-width: 100%;
    margin: 0 auto;
  `}
`;

export default function Features({ features, title }) {
  return (
    <Wrapper>
      <Container>
        <Inner>
          <Heading>{title}</Heading>
          {map(features, f => {
            return (
              <Feature key={f.id}>
                <div className="image">
                  <Icon fluid={f.icon.fluid} />
                </div>
                <div className="content">
                  <h3>{f.title}</h3>
                  <p>{f.body}</p>
                  <Link to="/features/">
                    <button>
                      <span>Learn More</span>
                    </button>
                  </Link>
                </div>
              </Feature>
            );
          })}
        </Inner>
      </Container>
    </Wrapper>
  );
}

Features.propTypes = featureTypes;
