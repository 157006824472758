import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Container, Inner, SectionHeading } from './Elements';
import { blogListingTypes } from '../types/propTypes';
import { colors } from '../consts/style';
import BlogCard from './BlogCard';

const Wrapper = styled.div`
  margin-bottom: 8rem;
`;

const PostWrapper = styled.div``;

export default function BlogListing({ blogPosts, blogsTitle }) {
  const colorMap = [colors.teal, colors.yellow, colors.pink];

  return (
    <Fragment>
      <Wrapper>
        <Container>
          <Inner>
            <SectionHeading>{blogsTitle}</SectionHeading>
            <PostWrapper className="row center-xs">
              {blogPosts.map((post, i) => (
                <BlogCard
                  key={post.id}
                  post={post}
                  highlightColor={colorMap[i]}
                />
              ))}
            </PostWrapper>
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}

BlogListing.propTypes = blogListingTypes;
