import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { genericBlockTypes } from '../types/propTypes';
import mq from '../style/mq';
import { Container, Inner } from './Elements';
import { font, colors, boxShadow } from '../consts/style';

const Wrapper = styled.div`
  background: ${props => props.bg};
  height: 60rem;
  position: relative;
  overflow: hidden;
  ${Container} {
    position: relative;
  }
  ${Inner} {
    display: flex;
    align-items: center;
    height: 100%;
    ${props => props.inverted && 'justify-content: flex-end;'}
  }
  ${mq.tabletSmall`
    height: 43rem;
  `}
`;

const Content = styled.div`
  width: 50%;
  position: relative;
  z-index: 10;
  color: ${props => props.color};
  h2 {
    ${font.heading};
  }
  p {
    ${font.p};
  }
  button {
    margin-top: 1.2rem;
    color: ${props => props.buttonTextColor};
    background: ${colors.dark};
    ${boxShadow.default};
    ${font.button};
  }
`;

const HeroImagery = styled.div`
  position: absolute;
  bottom: 0;
  width: 80%;
  z-index: 0;
  pointer-events: none;
  ${props => (props.inverted ? 'left: 0;' : 'right: 0;')}
  transform: translateX(${props => (props.inverted ? '-20%' : '20%')});
  ${mq.desktop`
    width: 90%;
  `}
  ${mq.tabletSmall`
    width: 160%;
    transform: translateX(${props => (props.inverted ? '-46%' : '46%')});
  `}
`;
const GIF = styled.img`
  width: 100%;
  max-width: 100%;
`;

export default function HomeBlock({
  bg,
  color,
  inverted,
  heading,
  body,
  gif,
  slug,
  buttonTextColor,
}) {
  return (
    <Wrapper bg={bg} inverted={inverted}>
      <Container>
        <Inner>
          <Content color={color} buttonTextColor={buttonTextColor}>
            <h2>{heading}</h2>
            <p>{body}</p>
            <Link to={slug}>
              <button>
                <span>Explore</span>
              </button>
            </Link>
          </Content>
          <HeroImagery inverted={inverted}>
            <GIF src={gif} />
          </HeroImagery>
        </Inner>
      </Container>
    </Wrapper>
  );
}

HomeBlock.propTypes = genericBlockTypes;
